import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { LocationPageQuery, Store, Location, File } from "../../graphql-types"
import Layout from "../../components/layout"
import StoreList from "../../partials/store/store-list"
import Container from "../../components/container"
import useVarieties from "../../hooks/use-varieties"
import useChains from "../../hooks/use-chains"
import LocationHero from "../../partials/location/location-hero"

export default function LocationPage({ data }: PageProps<LocationPageQuery>) {
  const varieties = useVarieties()
  const chains = useChains()

  return (
    <Layout noGap title={`Vintage stores in ${data.location.title}`}>
      <div className={"flex w-full flex-col gap-8"}>
        <LocationHero
          location={data.location as Location}
          fallbackHero={data.heroImage as File}
        />
        <Container className={"justify-center"}>
          <StoreList
            chains={chains}
            varieties={varieties}
            stores={data.location.stores as Store[]}
          />
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query LocationPage($id: String!) {
    location(id: { eq: $id }) {
      title
      slug
      body
      bannerImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      stores {
        ...StoreCommon
        ...StoreVarieties
        ...StoreChain
        ...StoreTileImages
        ...StoreLocation
      }
    }
    heroImage: file(relativePath: { eq: "opshop.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
