import * as React from "react"
import { File, Location } from "../../../graphql-types"
import Hero from "../../../components/hero"

interface LocationHeroProps {
  location: Location
  fallbackHero: File
}

export default function LocationHero({
  location,
  fallbackHero,
}: LocationHeroProps) {
  const featureImageToUse = location.bannerImage ?? fallbackHero

  return (
    <Hero
      title={location.title}
      heroImage={featureImageToUse}
      compact={location.body === ""}
    >
      {location.body && (
        <div
          className={"justify-center prose text-white"}
          dangerouslySetInnerHTML={{ __html: location.body }}
        />
      )}
    </Hero>
  )
}
